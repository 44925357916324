import React from 'react';
import './PromptWithActionLink.css';
import '../../styles/inputStyles.css'; // Make sure the path is correct and adjust based on your project structure


const PromptWithActionLink = ({ prompt, buttonText, onLinkPress }) => {
    // Логирование входных переменных
    //console.log('Prompt:', prompt);
    //console.log('Button text:', buttonText);
    //console.log('On link press function:', onLinkPress);

    return (
        <div className="promptWithActionLink-container">
            <span className="text promptWithActionLink-text ">{prompt} </span>
            <button onClick={onLinkPress} className="promptWithActionLink-link">
                {buttonText}
            </button>
        </div>
    );
};

export default PromptWithActionLink;