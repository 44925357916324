//// veryficateOnServer.js

import axios from 'axios';
import { IS_TEST_MODE, BASE_URL, API_VERIFY_ENDPOINT } from '../config/config';

// Функция для получения фиктивных данных верификации в тестовом режиме
const getMockVerificationData = () => {
    return {
        status: 200,
        data: {
            token: 'fake-token',
            user: {
                is_registration_completed: true,
                language: 'Русский',
                generalContent: true,
                businessContent: true,
                relationContent: true,
                healthContent: false,
                gender: "male",
                birth_date: "1966-09-04",
                birth_time: "00:53:28",
                birth_country: "Ukraine",
                birth_city: "Kharkiv",
                biography: '',
                subscription_type: 'Premium',
                subscriptionPerMonth: 0,
                subscriptionPerYear: 0,
            }
        }
    };
};

// Отправляет запрос верификации на сервер
const sendVerificationRequest = async ({ name, email, password, verification, language }) => {
    const url = `${BASE_URL}${API_VERIFY_ENDPOINT}`;
    axios.defaults.withCredentials = true;

    return axios.post(url, { name, email, password, verification, language }, {
        validateStatus: status => status < 500
    });
};

// Обрабатывает успешный ответ сервера
const processSuccessfulResponse = (response) => {
    if (!response.data || !response.data.data || typeof response.data.data !== 'object' || !response.data.data.token) {
        return handleErrorResponse('Invalid data format or missing token from server', 500);
    }

    const userToken = response.data.data.token;
    localStorage.setItem('userToken', userToken);

    return {
        status: 200,
        data: response.data.data
    };
};

// Генерирует объект ошибки и возвращает структурированный ответ об ошибке
const handleErrorResponse = (errorMessage, errorStatus) => {
    console.warn('Verification error:', errorMessage);
    return { status: errorStatus, data: { error: errorMessage } };
};

// Основная функция верификации
export const veryficateOnServer = async ({ name, email, password, verification, language }) => {
    if (IS_TEST_MODE) {
        return getMockVerificationData();
    }

    try {
        const response = await sendVerificationRequest({ name, email, password, verification, language });

        if (response.status === 200) {
            return processSuccessfulResponse(response);
        } else {
            return handleErrorResponse(response.data.data.error, response.status);
        }
    } catch (error) {
        return handleErrorResponse(error.message || 'Unknown error occurred', error.response ? error.response.status : 500);
    }
};

