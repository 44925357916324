import React, { useRef, useEffect, useState, useCallback } from 'react';

import '../../styles/inputStyles.css';

import BackgroundImage from '../BackgroundImage/BackgroundImage';
import AstrologAIText from '../AstrologAIText/AstrologAIText';
import { useTokenAuthentication } from '../../hooks/useTokenAuthentication';

import SignIn from '../signin/SignIn';
import SignUp from '../signup/SignUp';
import Prediction from '../prediction/Prediction';
import Verification from '../verification/Verification';
import Subscription from '../subscription/Subscription';
import Confirmation from '../subscription/Confirmation';
import UnsupportedCountryNotice from '../subscription/UnsupportedCountryNotice';
import OrderCompletedSuccess from '../subscription/OrderCompletedSuccess';
import OrderCompletedFailure from '../subscription/OrderCompletedFailure';
import Profile from '../profile/Profile';
import Terms from '../terms/Terms';
import GreetingForm from '../GreetingForm/GreetingForm';
import LanguageSelector from '../language/LanguageSelector';
import LanguageForm from '../language/LanguageForm';
import { useUser } from '../../context/UserContext';
import TranzilaPaymentForm from '../subscription/TranzilaPaymentForm';
import Footer from '../footer/Footer';
import ContactPage from '../footer/ContactPage';


import './MainPage.css';

function MainPage() {
    const [currentScreen, setCurrentScreen] = useState('null');
    const [screenHistory, setScreenHistory] = useState([]);
    const { checkToken } = useTokenAuthentication();
    const { user, setUser } = useUser();


    useEffect(() => {
        const authenticate = async () => {
            console.log('Starting authentication process');

            const urlParams = new URLSearchParams(window.location.search);
            const authData = urlParams.get('auth_data');

            if (authData) {
                console.log('Auth data found in URL:', authData);
                const decodedData = JSON.parse(decodeURIComponent(authData));
                if (decodedData.token) {
                    console.log('Setting token to localStorage');
                    localStorage.setItem('userToken', decodedData.token);
                }
                console.log('Cleaning URL params');
                window.history.replaceState({}, document.title, window.location.pathname);
            }

            console.log('Checking token...');
            const response = await checkToken();
            console.log('Token check response:', response);

            if (window.location.pathname === '/success/') {
                console.log('Redirecting to OrderCompletedSuccess');
                goToScreen('OrderCompletedSuccess');
                return;
            }

            if (window.location.pathname === '/failure/') {
                console.log('Redirecting to OrderCompletedFailure');
                goToScreen('OrderCompletedFailure');
                return;
            }

            if (!response.success) {
                console.log('Token check failed, redirecting to GreetingForm');
                goToScreen('GreetingForm');
                return;
            }

            if (!response.user.is_registration_completed || response.user.birth_country === "") {
                console.log('Registration incomplete or birth country missing, redirecting to Profile');
                console.log('is_registration_completed:', response.user.is_registration_completed);
                console.log('birth_country:', response.user.birth_country);
                goToScreen('Profile');
                return;
            }

            if (response.user.subscription_type === "NONE" || response.user.subscription_type === null) {
                console.log('No subscription found:', response.user.subscription_type);
                goToScreen('Subscription');
                return;
            }

            console.log('All checks passed, redirecting to Prediction');
            goToScreen('Prediction');
        };

        authenticate();
    }, []);

    const goToScreen = (screen) => {
        setCurrentScreen(screen);
        setScreenHistory([...screenHistory, screen]);
    };

    const goBack = () => {
        if (screenHistory.length > 1) {
            const newScreenHistory = [...screenHistory];
            newScreenHistory.pop();
            setScreenHistory(newScreenHistory);
            setCurrentScreen(newScreenHistory[newScreenHistory.length - 1]);
        }
        else {
            window.history.replaceState(null, '', '/');
            goToGreetingForm();
        }
            
    };

    const goToSignUp = () => goToScreen('SignUp');
    const goToSignIn = () => goToScreen('SignIn');
  /*  const goToTerms = () => goToScreen('Terms');*/
    const goToVerification = () => goToScreen('Verification');
    const goToProfile = () => goToScreen('Profile');
    const goToSubscription = () => goToScreen('Subscription');
    const goToPrediction = () => goToScreen('Prediction');
    const goToGreetingForm = () => goToScreen('GreetingForm');
    const goToLanguageForm = () => goToScreen('LanguageForm');
    const goToContactPage = () => goToScreen('ContactPage');

    const goToTermsWithForm = () => {
        goToScreen('TermsWithForm');
    };

    const goToTermsWithoutForm = () => {
        goToScreen('TermsWithoutForm');
    };

    const goToConfirmation = () => goToScreen('Confirmation');


    let amount = 10;
    let period = "yearly"
    let plan = "STANDARD"

    return (
        <BackgroundImage>
            <div className="main-page-top-aligned-div">
                <AstrologAIText />
                <LanguageSelector onSubmit={goToLanguageForm} onBack={goBack} />
            </div>
            <div className="main-page-container">

                {/* <TranzilaPaymentForm amount={amount} period={period } />*/}


                {/* {<OrderCompletedSuccess paymentMethod="Google Pay" lastFourDigits = "1234"/> }*/}
                {/* {<UnsupportedCountryNotice/> }*/}
                {/* {<Confirmation amount={amount} plan={plan} period={period} />}*/}
                {/* {<Subscription onSubmit={goToPrediction} onBack={goBack} header="true" />}*/}



                {currentScreen === 'OrderCompletedSuccess' && (
                    <OrderCompletedSuccess
                        message={new URLSearchParams(window.location.search).get('message') || ''}
                        onConfirm={goBack}
                    />
                )}
                {currentScreen === 'OrderCompletedFailure' && (
                    <OrderCompletedFailure
                        error={new URLSearchParams(window.location.search).get('error') || ''}
                        onConfirm={goBack}
                    />
                )}
                {currentScreen === 'GreetingForm' && <GreetingForm goToSignUp={goToSignUp} goToSignIn={goToSignIn} />}
                {currentScreen === 'SignUp' && <SignUp goToSignIn={goToSignIn}
                    goToTerms={goToTermsWithForm}
                    goToVerification={goToVerification}
                    goToProfile={goToProfile}
                    goToPrediction={goToPrediction}
                    onBack={goBack} />}
                {currentScreen === 'SignIn' && <SignIn goToPrediction={goToPrediction} goToSignUp={goToSignUp} onBack={goBack} />}
                {currentScreen === 'Verification' && <Verification onSubmit={goToProfile} onBack={goBack} />}
                {currentScreen === 'Profile' && <Profile onSubmit={goToSubscription} onBack={goBack} />}

                {currentScreen === 'Subscription' && <Subscription onSubmit={goToPrediction} onBack={goBack} header="true" />}
                {currentScreen === 'Confirmation' && <Confirmation amount={5} plan={"STANDARD"} period={"monthly"} onBack={goBack} />}

                {currentScreen === 'Prediction' && <Prediction goToGreetingForm={goToGreetingForm} />}


                {currentScreen === 'TermsWithForm' && <Terms goToSignUp={goToSignUp} onBack={goBack} showForm={true} />}
                {currentScreen === 'TermsWithoutForm' && <Terms goToSignUp={goToSignUp} onBack={goBack} showForm={false} />}

                {currentScreen === 'LanguageForm' && <LanguageForm onSubmit={goBack} onBack={goBack} />}  
                {currentScreen === 'ContactPage' && <ContactPage onBack={goBack}  />}
            </div>
            <div className="main-page-bottom-aligned-div">
                <Footer goToContactPage={goToContactPage} goToTerms={goToTermsWithoutForm} goToConfirmation={goToConfirmation} />
            </div>
        </BackgroundImage>
    );
}

export default MainPage;
