import axios from 'axios';

// Импорт констант из конфигурационного файла
import { IS_TEST_MODE, BASE_URL, API_LOGIN_ENDPOINT } from '../config/config'; 


// Функция для получения фиктивных данных аутентификации в тестовом режиме
const getMockAuthenticationData = () => {
    return {
        status: 200,
        data: {
            token: 'fake-token',
            user: {
                is_registration_completed: true,
                astrobot: "Bruce",
                language: 'Русский',
                generalContent: true,
                businessContent: true,
                relationContent: true,
                healthContent: false,
                name: "hkutomanov",
                aspectsContent: false,
                gender: "male",
                birth_date: "1966-09-04",
                birth_time: "00:53:28",
                birth_country: "Ukraine",
                birth_city: "Kharkiv",
                biography: '',
                subscription_type: 'Premium',
                subscriptionPerMonth: 0,
                subscriptionPerYear: 0,
            }
        }
    };
};

// Отправляет запрос аутентификации по имени пользователя и паролю на сервер
const sendAuthenticationRequest = async (username, password) => {
    //const endpoint = API_LOGIN_ENDPOINT;
    const url = `${BASE_URL}${API_LOGIN_ENDPOINT}`;

    axios.defaults.withCredentials = true;

    return axios.post(url, { username, password }, {
        validateStatus: status => status < 500
    });
};

// Обрабатывает успешный ответ сервера, проверяя наличие необходимых данных и токена
const processSuccessfulResponse = (response) => {

     // Проверяем наличие необходимых данных и токена в ответе
    if (!response.data || !response.data.data || typeof response.data.data !== 'object' || !response.data.data.token) {
        return handleErrorResponse('Invalid data format or missing token from server', 500);
    }

    const userToken = response.data.data.token;
    localStorage.setItem('userToken', userToken);

    return {
        status: 200,
        data: response.data.data
    };
};

// Генерирует объект ошибки, логирует ее и возвращает структурированный ответ об ошибке
const handleErrorResponse = (errorMessage, errorStatus) => {
    console.warn('Authentication error:', errorMessage);
    return { status: errorStatus, data: { error: errorMessage } };
};

// Основная функция аутентификации, обрабатывает всю логику запроса и ответа
const authenticateSignInOnServer = async ({ username, password }) => {
    if (IS_TEST_MODE) {
        return getMockAuthenticationData();
    }

    try {
        const response = await sendAuthenticationRequest(username, password);

        if (response.status === 200) {
            return processSuccessfulResponse(response);
        } else {
            return handleErrorResponse(response.data.data.error, response.status);
        }
    } catch (error) {
        return handleErrorResponse(error.message || 'Unknown error occurred', error.response ? error.response.status : 500);
    }
};

export default authenticateSignInOnServer;

