import React from 'react';
import { UserProvider } from './context/UserContext';
import MainPage from './components/MainPage/MainPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import OrderCompletedSuccess from './components/subscription/OrderCompletedSuccess';
import OrderCompletedFailure from './components/subscription/OrderCompletedFailure';
import useOverlayHeightAdjustment from './hooks/useOverlayHeightAdjustment';

function App() {

    useOverlayHeightAdjustment();

    return (
        <UserProvider>
            <MainPage />
        </UserProvider>
    );
}

export default App;
