import React from 'react';
import './Confirmation.css';
import '../../styles/inputStyles.css';
import SubmitButton from '../common/SubmitButton';
import appConfig from '../../static/json/appConfig.json';
import '../../styles/inputStyles.css';
import { useUser } from '../../context/UserContext';

const OrderCompletedSuccess = ({ onConfirm, message }) => {
    const { user } = useUser();
    const commonText = appConfig[user.language]["common"];

    return (
        <div className="overlay bottom-div parent-centered-div">
            <div className="centered-div">
                <div className="success-container text">
                    <div className="success-icon-circle">
                        <span role="img" aria-label="success" style={{ color: '#4CAF50', fontSize: '60px' }}>✓</span>
                    </div>

                    <h2 className="titleText">
                        {commonText["Order completed successfully!"]}
                    </h2>
                    <p className="success-message">
                        {message}
                    </p>

                    <p className="success-message">
                        {commonText["Check your email for your receipt."]}
                    </p>
                    <SubmitButton
                        text={commonText["Close"]}
                        onSubmit={onConfirm}
                    />
                </div>
            </div>
        </div>
    );
};

export default OrderCompletedSuccess;