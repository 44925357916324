import { useState, useCallback } from "react";
import sendUserInfoToServer from '../services/sendUserInfoToServer';
import useUserState from './useUserState';

export const useSendUserInfo = () => {
    const [loading, setLoading] = useState(false);
    const { updateUserData } = useUserState();

    const sendUserInfo = useCallback(async (userInfo, section) => {
        setLoading(true);

        try {
            const response = await sendUserInfoToServer(userInfo, section);

            if (response.status === 200 && !response.data.error) {
                updateUserData(response.data.user);
                return { success: true, user: response.data.user };
            } else if (response.status === 401) {
                updateUserData({ isAuthenticated: false });
                return { success: false, error: response.data.error };
            } else {
                return { success: false, error: response.data.error };
            }
        } catch (error) {
            return { success: false, error: error.message };
        } finally {
            setLoading(false);
        }
    }, [updateUserData]);

    return { sendUserInfo, loading };
};







