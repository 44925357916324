import { useState, useCallback } from 'react';
import { useUser } from '../context/UserContext';
import googleService from '../services/googleService';

export const useSocialAuthentication = () => {
    const { setUser } = useUser();
    const [loading, setLoading] = useState(false);

    const authenticateSocial = useCallback(async (socialNetwork) => {
        setLoading(true);
        try {
            if (socialNetwork === 'google') {
                const response = await googleService();
                // В случае мока получим успешный ответ
                if (response?.status === 200) {
                    return { success: true };
                }
                // В случае ошибки
                if (response) {
                    return {
                        success: false,
                        error: response?.data?.error || 'Google authentication error'
                    };
                }
                // При успешной реальной авторизации будет редирект
            } else {
                throw new Error('Unsupported social network');
            }
        } catch (error) {
            return {
                success: false,
                error: error.message || 'Authentication error'
            };
        } finally {
            setLoading(false);
        }
    }, [setUser, setLoading]);

    return {
        authenticateSocial,
        loading
    };
};