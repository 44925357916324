// Импорт необходимых модулей
import React, { useState } from 'react';
import appConfig from '../../static/json/appConfig.json';

import useFocusManagement from '../../hooks/useFocusManagement';
import { useScreenOffsetControl } from '../../hooks/useScreenOffsetControl';
import useBackHandler from '../../hooks/useBackHandler';

import CustomForm from '../common/CustomForm';
import { componentInstaller } from '../../utils/componentInstaller';

import { useUser } from '../../context/UserContext';
import '../../styles/inputStyles.css';
import './ContentSelector.css';

import { useSendUserInfo } from '../../hooks/useSendUserInfo';

// Основной компонент формы профиля
const ContentSelector = ({ onSubmit, onBack }) => {

    const { user, setUser } = useUser();
    const { sendUserInfo, loading } = useSendUserInfo();
    const [authError, setAuthError] = useState(null);

    // Загрузка метаданных полей из JSON
    const fieldMetadataArray = appConfig[user.language]["contentMetadataArray"];
    const commonText = appConfig[user.language]["common"];

    // Создание массива идентификаторов ссылок
    const refIdentifiers = fieldMetadataArray.map(item => item.name);

    // Создание объекта смещений для каждого поля
    const screenOffsets = Object.fromEntries(
        fieldMetadataArray.map(item => [item.name, item.screenOffset])
    );

    // Хук для управления смещением экрана
    const [screenOffset, setFieldOffset, panResponder, updateContentHeight] = useScreenOffsetControl();

    // Хуки для управления фокусом
    const { setRef, removeFocusFromAll, nextFieldFocus } = useFocusManagement(refIdentifiers);

    // Создание конфигурации полей
    const fieldsConfig = fieldMetadataArray.map(metadata => ({
        ...metadata,
        component: componentInstaller(metadata.component),
    }));

    const onSubmitForm = async (values) => {
        //console.log('LanguageForm onSubmitForm values:', values);
        const response = await sendUserInfo({
            generalContent: values.generalContent,
            businessContent: values.businessContent,
            relationContent: values.relationContent,
            healthContent: values.healthContent,
        }, 'content_preferences');
        //console.log('LanguageForm onSubmitForm response:', response);

        if (!response.success) {
            setAuthError(response.error);
        }
        else {
            onSubmit();
        }
    };

    useBackHandler(onBack);

    if (loading) {
        return (
            <div class="content-selector-loading-container">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <div className="overlay parent-centered-div">
            <div className="centered-div">
                <h1 class="titleText">
                    {commonText["Select prediction sections"]}
                </h1>

                <CustomForm
                    fieldsConfig={fieldsConfig}
                    setRef={setRef}
                    removeFocusFromAll={removeFocusFromAll}
                    nextFieldFocus={nextFieldFocus}
                    initialValues={{
                        generalContent: user.generalContent,
                        businessContent: user.businessContent,
                        relationContent: user.relationContent,
                        healthContent: user.healthContent,
                    }}
                    onSubmit={onSubmitForm}
                    submitText={commonText["Select"]}
                />

                {authError && <div className="error-text">{authError}</div>}

            </div>
        </div>

    );
};

export default ContentSelector;