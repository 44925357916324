import React, { useRef, useEffect, useState } from 'react';
import './Prediction.css';
import '../../styles/inputStyles.css';

const PredictionList = ({ articles, onLoadMore, selectedZodiac, selectedTop }) => {
    const scrollRef = useRef(null);
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTo({ top: 0, behavior: 'smooth' });
            setIsInitialLoad(false);
        }
    }, [isInitialLoad, selectedTop]);

    useEffect(() => {
        // Проверка высоты контейнера для автоматической подгрузки
        const checkContainerHeight = () => {
            if (scrollRef.current) {
                const { clientHeight, scrollHeight } = scrollRef.current;
                if (scrollHeight <= clientHeight) {
                    onLoadMore();
                }
            }
        };

        // Проверка после первой загрузки или обновления списка статей
        checkContainerHeight();
    }, [articles, onLoadMore]);

    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        if (scrollHeight - scrollTop <= clientHeight * 2) { // Проверяем, достигнут ли конец
            onLoadMore();
        }
    };

    const renderItem = (item) => {
        return (
            <div key={item.id} className="prediction-list-itemContainer">
                <div className="prediction-list-header">
                    <div className="prediction-list-titleContainer">
                        <span className="titleText prediction-list-titleText">
                            {selectedZodiac} {item.title}
                        </span>
                    </div>
                </div>

                {item.image && (
                    <img
                        src={item.image}
                        className={`prediction-list-itemImage`}
                        data-id={item.id}
                        alt="Prediction"
                    />
                )}

                <span
                    className="text prediction-list-descriptionText"
                    dangerouslySetInnerHTML={{ __html: item.content }}
                />
            </div>
        );
    };


    return (
        <div
            className="prediction-list-container"
            ref={scrollRef}
            onScroll={handleScroll}
            style={{ overflowY: 'auto', height: '100%' }}
        >
            {articles.map(renderItem)}
        </div>
    );
};

export default PredictionList;
