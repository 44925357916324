import { useEffect } from 'react';
import '../styles/designConstants.css';

const useOverlayHeightAdjustment = () => {
    useEffect(() => {
        const adjustOverlayHeight = () => {
            const root = document.documentElement;

            // Проверка на мобильное устройство и наличие клавиатуры
            if (window.visualViewport && window.visualViewport.height < window.innerHeight) {
                const adjustedHeight = `calc(${window.visualViewport.height}px - var(--header-height) - 30px)`;
                root.style.setProperty('--overlay-max-height', adjustedHeight);
            } else {
                // Восстанавливаем исходную высоту
                root.style.setProperty('--overlay-max-height', 'calc(100vh - var(--header-height) - var(--footer-height) - 60px)');
            }
        };

        window.visualViewport.addEventListener('resize', adjustOverlayHeight);

        // Убираем обработчик при размонтировании
        return () => {
            window.visualViewport.removeEventListener('resize', adjustOverlayHeight);
        };
    }, []);
};

export default useOverlayHeightAdjustment;
