// SubscriptionPage.js
import React from "react";
import { useUser } from '../../context/UserContext';
import appConfig from '../../static/json/appConfig.json';

import FeatureBox from '../common/FeatureBox';
import Container from '../common/Container';
import SubmitButton from '../common/SubmitButton';

import '../../styles/inputStyles.css';
import '../../styles/colors.css';
import '../../styles/designConstants.css';
import './SubscriptionPage.css';

const SubscriptionPage = ({ data, onSubmit, onPrevious, onNext, isFirstPage, isLastPage }) => {
    const { user } = useUser();
    const commonText = appConfig[user.language]["common"];

    const { Title, PerMonth, PerYear, Items, Description } = data;

    //console.log(`SubscriptionPage Title ${Title} onPrevious ${onPrevious} onNext ${onNext} isFirstPage ${isFirstPage} isLastPage ${isLastPage}`);

    const onSubmitSubscription = (buttonType) => {
        // setUser logic
        onSubmit({
            subscription_type: Title,
            subscriptionPerMonth: buttonType === "monthly" ? PerMonth : 0,
            subscriptionPerYear: buttonType === "monthly" ? 0 : PerYear
        });


    };

    return (
        <div className="container parent-centered-div subscriptionpage-container">
            <div className="subscriptionpage-container-2">
                <div className="subscriptionpage-inner-container">
                    <h2 className="titleText subscriptionpage-title">{Title}</h2>

                    <div>
                        {PerMonth == 0 ? (
                            <div className="text subscriptionpage-font20 subscriptionpage-pricing1">{`${PerMonth}$ / month`}</div>
                        ) : (
                            <div className="subscriptionpage-rowContainer">
                                <div className="text subscriptionpage-font20 subscriptionpage-pricing">{`${PerMonth}$ / month`}</div>
                                <div className="text subscriptionpage-font20 subscriptionpage-pricing">or</div>
                                <div className="text subscriptionpage-font20 subscriptionpage-pricing">{`${PerYear}$ / year`}</div>
                            </div>
                        )}
                    </div>
                    <div class="subscriptionpage-image-container">
                        <div
                            className={`arrow left-arrow ${isFirstPage ? 'transparent' : ''}`}
                            onClick={!isFirstPage ? onPrevious : undefined}
                        >
                            &lt;
                        </div>

                        <div class="subscriptionpage-image-container">
                            <div className="subscriptionpage-featuresContainer">
                                {Object.entries(Items).map(([key, value], index) => (
                                    <FeatureBox key={index} label={key} isChecked={value === 'true'} />
                                ))}
                            </div>
                        </div>

                        <div
                            className={`arrow right-arrow ${isLastPage ? 'transparent' : ''}`}
                            onClick={!isLastPage ? onNext : undefined}
                        >
                            &gt;
                        </div>
                    </div>
                    <div className="text subscriptionpage-description">{Description}</div>

                    <div className="subscriptionpage-submitFrame">
                        {PerMonth == 0 ? (
                            <div className="subscriptionpage-submitButton singleButton">
                                <SubmitButton text={commonText["Select"]} onSubmit={() => onSubmitSubscription("monthly")} />
                            </div>
                        ) : (
                            <>
                                <div className="subscriptionpage-submitButton">
                                    <SubmitButton text={commonText["Select monthly"]} onSubmit={() => onSubmitSubscription("monthly")} />
                                </div>
                                <div className="subscriptionpage-submitButton">
                                    <SubmitButton text={commonText["Select yearly"]} onSubmit={() => onSubmitSubscription("yearly")} />
                                </div>
                            </>
                        )}
                    </div>

                </div>


            </div>
        </div>
    );
};

export default SubscriptionPage;