import React, { useState } from 'react';
import './SocialLogin.css';
import SocialLoginButton from './SocialLoginButton'; // Assuming the new component is imported correctly
import facebookLogo from './FacebookLogo.png'; // Ensure the path is correct
import appleLogo from './AppleLogo.png';
import googleLogo from './GoogleLogo.png';

import '../../styles/inputStyles.css'; // Make sure the path is correct
import { useSocialAuthentication } from '../../hooks/useSocialAuthentication';

import { useUser } from '../../context/UserContext';
import appConfig from '../../static/json/appConfig.json';

const SocialLogin = ({ navigation }) => {
    const [authError, setAuthError] = useState(null);
    const { user, setUser } = useUser();
    const { authenticateSocial, loading } = useSocialAuthentication();
    const commonText = appConfig[user.language]["common"];

    
    const onSubmit = async (values) => {
        setAuthError("");

        const { success, error } = await authenticateSocial(values);
        if (success) {
            navigation.navigate('GreetingForm');
        } else {
            setAuthError(error);
        }
    };

    return (
        <div >
            <div className="text sociallogin-sign-text"> 
                {commonText["Or sign in with..."]}
            </div>
            <div className="sociallogin-continueWithSocialsParent">
                {/*<SocialLoginButton imageSource={facebookLogo} onPress={() => onSubmit("facebook")} />*/}
                {/*<SocialLoginButton imageSource={appleLogo} onPress={() => onSubmit("apple")} className="continueWithAppleLeftAli1" />*/}
                <SocialLoginButton imageSource={googleLogo} onPress={() => onSubmit("google")} />
            </div>
            {loading && <div className="sociallogin-loader">Loading...</div>}
            {authError && <div>{authError}</div>}
        </div>
    );
};

export default SocialLogin;